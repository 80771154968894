import $ from 'jquery';
import React from 'react';
import View_Document_Comp from './view_document_comp';
let listFile=[];
let listDocuments=[];
let _tagCountry=[];
export const handelChange=(event)=>{
 alert("i m on change")
}

export const Comp_Workbook_Form=(props)=>{
    
    //alert("i m in props "+filterList.length);
    return (<div>
        {props.WBFormName!==""?<iframe width="100%" height="500px" src={"https://cms.1040-prep.com/controller/Itr_Controller.php?pm="+props.WBFormName}>
        </iframe>:''}
    
        </div>
                )

}

export default Comp_Workbook_Form