import $ from 'jquery';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import View_Document_Comp from './view_document_comp';
let listFile=[];
let listDocuments=[];
let _tagCountry=[];
export const handelChange=(event,docItem)=>{
 //alert("i m on change "+event.target.name);
 let respSubQuest=Sub_Questionnaire(docItem,event.target.value);
 let strHtmlSubQust=ReactDOMServer.renderToString(respSubQuest);
 $("#"+docItem._id.$oid+"_subquest").html(strHtmlSubQust);
}
export const Set_Answers_Values=()=>{
    //alert("called in last of set");
    for(let i=0;i<listDocuments.lenght;i++){
        $("#"+listDocuments[i]._id.$oid).val(listDocuments[i].doc_value);
    }
}
export const Sub_Questionnaire=(p_docitem,p_value)=>{
    //alert("List Doc Lenght=="+p_value);
    //let filterList=listDocuments;
    const listFltr1 = d1 => (d1.on_event === 'child' && d1.p_quest === p_docitem._id.$oid && d1.p_value===p_value);
    //const listFltr1 = d1 => (d1.on_event === 'child' && d1.p_quest === p_docitem._id.$oid);
    let filterList=listDocuments.filter(listFltr1);
    //alert("i m in props "+filterList.length);
    return (<div class="sub-quest bg-gray ml-2 mr-2 mb-2">
        {filterList.map((docitem,di)=>{
        let indxList=0;
                   
                    //indxList++;
                    if(docitem.type[0]==="text" || docitem.type[0]===""){
                        return( <tr><td><div className="row">
                        <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">{docitem.title}</label>
                                    <div className="input-group input-group-sm">
                                        <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={""} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 vcenter-item'>
                            {docitem.status}
                            {docitem.reason_msg}
                            </div>
                         </div></td></tr>)
                    }else if(docitem.type[0]==="radio_button"){
                        //alert(docitem.doc_value);
                        let strRadioList=docitem.descr;
                        let arrRadioList=strRadioList.split('>');
                        //alert(arrRadioList.length);
                        
                        return(<tr><td><div className="row">
                        <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">{docitem.title}</label>
                                    <div className="input-group input-group-sm">
                                       {arrRadioList.map((itemRadio,indexRadio)=>{
                                        return (<div><input id={docitem._id.$oid+"_"+itemRadio} name={docitem._id.$oid} type="radio" value={itemRadio} />
                                        <label for={docitem._id.$oid+"_"+itemRadio}>{itemRadio}</label>
                                        </div>);
                                       })}
                                        {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 vcenter-item'>
                            {docitem.status}
                            {docitem.reason_msg}
                            </div>
                         </div>
                        
                         </td></tr>)
                    }else if(docitem.type[0]==="dropdown"){
                        let strDropdownList=docitem.descr;
                        let arrDropdownList=strDropdownList.split('>');
                        //alert(docitem.doc_value);
                        
                        return(<tr><td><div className="row">
                        <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">{docitem.title}</label>
                                    <div className="input-group input-group-sm">
                                        <select id={docitem._id.$oid} name={docitem._id.$oid} className="form-control">
                                           <option value="0">- Select -</option>
                                          
                                           {arrDropdownList.map((itemdd,indexdd)=>{
                                            if(itemdd==="{#country#}"){
                                              //  alert("i m in condition "+docitem.title);
                                             return _tagCountry.map((country,indexco)=>{
                                                return (<option selected={docitem.doc_value===country["name"]} value={country["name"]}>{country["name"]}</option>)
                                               })
                                            }else{
                                                return (<option selected={docitem.doc_value===itemdd} value={itemdd}>{itemdd}</option>);
                                            }
                                        

                                       })}
                                        </select>
                                       
                                        {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 vcenter-item'>
                            {docitem.status}
                            {docitem.reason_msg}
                            </div>
                         </div></td></tr>)
                    }else if(docitem.type[0]==="date"){
                        
                        return(<tr><td> <div className="row">
                        <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">{docitem.title}</label>
                                    <div className="input-group input-group-sm">
                                        <input defaultValue={docitem.doc_value}  autoComplete='off' type="date" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 vcenter-item'>
                            {docitem.status}
                            {docitem.reason_msg}
                            </div>
                         </div></td></tr>)
                    }
                    else if(docitem.type[0]==="file_upload"){
                       let uploadedCheck="";
                       if(docitem.doc_path!==""){
                           uploadedCheck="<a href=\"javascript:void(0)\">View</a>"
                       }
                    return(<tr><td> <div className="row">
                    <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label for="exampleInputEmail1">{docitem.title}</label>{uploadedCheck!==""?<a onClick={()=>this.viewFile(docitem)} href='javascript:void(0)'>View Uploaded</a>:''}
                                <div className="input-group input-group-sm">
                                    <input type="file" name={docitem._id.$oid} className="form-control" placeholder="Check Contact No." />
                                </div>
                            </div>
                        </div>
                        <div className='col-3 vcenter-item'>
                            {docitem.status}
                            {docitem.reason_msg}
                            </div>
                     </div></td></tr>)
                   } 
                   
            
                })}</div>)
}
export const Questionnaire_Comp_Func=(props)=>{
    
    let logInObj=props.logInObj;
    let docHeader=[];
    let applicantList=[];
    
    
    let listDoc=[]
    _tagCountry=props.countryList
    if(props.viewLoadDoc.length>0){
        listDocuments=props.viewLoadDoc[0].doc_list;
        listDoc=props.viewLoadDoc[0].doc_list;

        applicantList.push("Questionnair");
        docHeader.push("General Information");
        docHeader.push("Income");
        docHeader.push("Gain-Loss");
        docHeader.push("Deduction");
        docHeader.push("Miscellaneous");
         
         
         let checkDocHeaderDisplay=[];
         for(let h=0;h<listDoc.length;h++){
             let headerName=listDoc[h]["doc_header"];
             if(!(checkDocHeaderDisplay.includes(headerName))){
               checkDocHeaderDisplay.push(headerName);
             }
       }
       /* remove not required docHeader */
       for(let a=0;a<docHeader.length;a++){
           if(!checkDocHeaderDisplay.includes(docHeader[a])){
               docHeader.splice(a,1);
           }
       }


    }
    return(<div>
        <div id="retesting"></div>
    {(listDoc.length>0?<div className="modal-content">
      {/* <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{this.props.viewLoadDoc[0]["_category"]}</h5>
          
        </div> */}
          {/* View docu modal */}
          <View_Document_Comp view_doc={listFile}/>
        {/* End of View Docu Modal */}
        <form id="msform" name="msform" className='msform' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="res_func" id="res_func" value="okSuccUpdate" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="updateLoanRequestData" />
                                  <input type="hidden" name="m_type" id="m_type" value="updateLoanRequestData" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="loan_request_id" id="loan_request_id" value={props.viewLoadDoc[0]._id.$oid}/>
                                  <input type="hidden" name="local_token" id="local_token" value={props.viewLoadDoc[0]._id.$oid}/>
                                  <input type="hidden" name="applicant_index" id="applicant_index" value={"0"}/>

            {(logInObj==="")?<div className='row'>
                <div className='col-12'>{"Welcome "+props.viewLoadDoc[0]["first_name"]+" "+""+" , Kindly fill requested form to check '"+props.viewLoadDoc[0]["_category"]+"' eligibility"}</div>
            </div>:''}
        
             
{/* new type of document header */}
<ul class="nav nav-tabs justify-content-center scrollmenu mb-10" id="myTab" role="tablist">
{docHeader.map((item,index)=>{
let docuemntDivId=item.replace(" ","_");
docuemntDivId+=index;
let linkStatus="";
if(index==0){
    linkStatus="active"
}
return (<li class="nav-item" role="presentation">
<button class={"nav-link"+" "+linkStatus} id={"tab"+docuemntDivId} data-bs-toggle="tab" data-bs-target={"#"+docuemntDivId} type="button" role="tab" aria-controls={docuemntDivId} aria-selected="true">{item}</button>
</li>)
})}
</ul>

<div id="myTabContent" class="col-md-12 col-xs-12 panel panel-default no-border single-my-account1 tab-content">
{(docHeader.map((item,index)=>{
//alert("doc== ["+item+"]");
 let docuemntDivId=item.replace(" ","_");
 docuemntDivId+=index;
 let tabStatus="";
 if(index==0){
    tabStatus="show active"
 }
                                return(<div id={docuemntDivId} class={"tab-pane container fade "+tabStatus} role="tabpanel" aria-labelledby={docuemntDivId} >
                            <div class="panel-body">
                                <table id="tbQustData">
                                    <tr><td></td></tr>
                            {listDocuments.map((docitem,di)=>{
                                let indxList=0;
                                {/* 64f1cfbc87018898e2022e39
64f1cfbc87018898e2022d7b
64f1cfbc87018898e2022e46    */}
                                if(docitem._id.$oid==="64f1cfbc87018898e2022e46"){
                                    //alert(JSON.stringify(docitem));
                                }
                                
                                           if(docitem.doc_header===item && docitem.on_event!=="child"){
                                            //indxList++;
                                            if(docitem.type[0]==="text" || docitem.type[0]===""){
                                                return( <tr><td><div className="row border-bottom">
                                                <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">{docitem.title}</label>
                                                            <div className="input-group input-group-sm">
                                                                <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={""} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 vcenter-item'>
                                                    {docitem.status}
                                                    {docitem.reason_msg}
                                                    </div>
                                                 </div></td></tr>)
                                            }else if(docitem.type[0]==="radio_button"){
                                                //alert(docitem.doc_value);
                                                let strRadioList=docitem.descr;
                                                let arrRadioList=strRadioList.split('>');
                                                //alert(arrRadioList.length);
                                                
                                                return(<tr><td><div className="row border-bottom">
                                                <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">{docitem.title}</label>
                                                            <div className="input-group input-group-sm">
                                                               {arrRadioList.map((itemRadio,indexRadio)=>{
                                                                return (<div><input onChange={(event)=>handelChange(event,docitem)} defaultChecked={docitem.doc_value===itemRadio} id={(docitem._id.$oid+"_"+itemRadio).trim()} name={docitem._id.$oid} type="radio" value={itemRadio} />
                                                                <label for={(docitem._id.$oid+"_"+itemRadio).trim()}>{itemRadio}</label>
                                                                </div>);
                                                               })}
                                                                {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 vcenter-item'>
                                                    {docitem.status}
                                                    {docitem.reason_msg}
                                                    </div>
                                                    {/* for sub quest */}
                                                    {docitem.on_event==="parent"?<div className="col-lg-12 col-md-12" id={docitem._id.$oid+"_subquest"}>
                                                    {Sub_Questionnaire(docitem,docitem.doc_value)}</div>:''}
                                                    {/*end of sub quest */}
                                                    
                                                 </div>
                                                
                                                 </td></tr>)
                                            }else if(docitem.type[0]==="dropdown"){
                                                let strDropdownList=docitem.descr;
                                                let arrDropdownList=strDropdownList.split('>');
                                                //alert(docitem.doc_value);
                                                
                                                return(<tr><td><div className="row border-bottom">
                                                <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">{docitem.title+"tt"+docitem.on_event}</label>
                                                            <div className="input-group input-group-sm">
                                                                <select id={docitem._id.$oid} name={docitem._id.$oid} className="form-control">
                                                                   <option value="0">- Select -</option>
                                                                  
                                                                   {arrDropdownList.map((itemdd,indexdd)=>{
                                                                    if(itemdd==="{#country#}"){
                                                                      //  alert("i m in condition "+docitem.title);
                                                                     return _tagCountry.map((country,indexco)=>{
                                                                        return (<option selected={docitem.doc_value===country["name"]} value={country["name"]}>{country["name"]}</option>)
                                                                       })
                                                                    }else{
                                                                        return (<option selected={docitem.doc_value===itemdd} value={itemdd}>{itemdd}</option>);
                                                                    }
                                                                

                                                               })}
                                                                </select>
                                                               
                                                                {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 vcenter-item'>
                                                    {docitem.status}
                                                    {docitem.reason_msg}
                                                    </div>
                                                 </div></td></tr>)
                                            }else if(docitem.type[0]==="date"){
                                                
                                                return(<tr><td> <div className="row border-bottom">
                                                <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">{docitem.title}</label>
                                                            <div className="input-group input-group-sm">
                                                                <input defaultValue={docitem.doc_value}  autoComplete='off' type="date" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 vcenter-item'>
                                                    {docitem.status}
                                                    {docitem.reason_msg}
                                                    </div>
                                                 </div></td></tr>)
                                            }
                                            else if(docitem.type[0]==="file_upload"){
                                               let uploadedCheck="";
                                               if(docitem.doc_path!==""){
                                                   uploadedCheck="<a href=\"javascript:void(0)\">View</a>"
                                               }
                                            return(<tr><td> <div className="row border-bottom">
                                            <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label for="exampleInputEmail1">{docitem.title}</label>{uploadedCheck!==""?<a onClick={()=>this.viewFile(docitem)} href='javascript:void(0)'>View Uploaded</a>:''}
                                                        <div className="input-group input-group-sm">
                                                            <input type="file" name={docitem._id.$oid} className="form-control" placeholder="Check Contact No." />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-3 vcenter-item'>
                                                    {docitem.status}
                                                    {docitem.reason_msg}
                                                    </div>
                                             </div></td></tr>)
                                           } 
                                           }
                                    
                                      })}
                               
                               </table>
                               
                            </div>
                        </div>
                    )
                    
                              }))}

</div>     
{/* end of main div tab */}
                                  <div className="col-md-6 respSSL_lod red"></div>
                                  <div id="testing2dd2222"></div>
                            
       
        </form>
      </div>:'')}
      {/* {Set_Answers_Values()} */}
      </div>)
}
export default Questionnaire_Comp_Func