import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {new_enc_dec_m,CallMapMethod,BtnSetColor,webUrlPath,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp,setDocLinks,GetImgCertUser} from '../function_lib/common_lib';
import {getCountryList} from '../function_lib/city_india';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';

//import Questionnair_Component from '../user_components/questionnair_component';
import Comp_Workbook_Form from '../user_components/comp_workbook_form';


let loginUser="";
let userObject="";
let clsIntWorkBook;

let webUrl;
let rf="0";
let queries;

/* internal param */
let yearRf="0";
let yearTitle="";
let countryList=[];
let formListOption=[];

class Int_WorkBook extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!==null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        countryList=getCountryList();
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(typeof queries.doc_rf!=="undefined"){
                    rf=queries.doc_rf;
                  }
                  if(typeof queries.rf!=="undefined"){
                    yearRf=queries.rf;
                  }
                  if(typeof queries.dis!=="undefined"){
                    yearTitle=new_enc_dec_m("decrypt",queries.dis);
                  }
          }
          this.state={ listLoanRequest:[],viewLoadDoc:[],stType:"questionnair",
                      stYearRf:yearRf,stYearTitle:yearTitle,
                    stWBFormName:""};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          webUrl=webUrlPath();
          clsIntWorkBook=this;
          formListOption.push({"title":"General Information","form_load":"form1040"});
          formListOption.push({"title":"1040","form_load":"form1040"});
          formListOption.push({"title":"1065 K1","form_load":"form1065_k1"});
          formListOption.push({"title":"1098","form_load":"form1098"});
          formListOption.push({"title":"1098 T","form_load":"form1098_t"});
          formListOption.push({"title":"1099 Q","form_load":"form1099q"});
          formListOption.push({"title":"1099 DIV","form_load":"form1099div"});
          formListOption.push({"title":"1099 G","form_load":"form1099g"});
          formListOption.push({"title":"1099 INT","form_load":"form1099int"});
          formListOption.push({"title":"1099 MISC","form_load":"form1099misc"});
          formListOption.push({"title":"1099 R","form_load":"form1099r"});
          formListOption.push({"title":"FORM W2","form_load":"formw2"});
          formListOption.push({"title":"FORM K1-1120","form_load":"formk1_1120"});
          formListOption.push({"title":"Automation Initiator","form_load":"automationInit"});
      }

      
      setFormState(formName){
        clsIntWorkBook.setState({stWBFormName:formName});
      }
      
      componentDidMount(){
         
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
        
          document.body.appendChild(script);
      }
    

    render(){
   
        return(<div className="bg-gray">
            <Header doc_rf={rf}/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                {/* side card */}
              <div className="col-md-2 pb-20px pt-20px">
              <div class="card" style={{width: "100%;"}}>
  
  <div class="card-body">
     <h5 class="card-title">Main Info</h5>
  </div>
  <ul class="list-group list-group-flush">
    {formListOption.map((item,index)=>{
        return <li class="list-group-item"><a className="w-100" onClick={()=>this.setFormState(item.form_load)} href="javascript:void(0)">{item.title}</a></li>
    })}
  </ul>
  
</div>

              </div>
{/* end of card */}
             
                <div className="col-md-10 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title">WorkBook ({this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["year_display"]:""})</h5>
                {rf!=="0" && rf!==0?<p>{this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["first_name"]:''}</p>:''}
                </div>
                <input type="hidden" id="maxRows" name="state" value={10}/>
              <div class="card-body">
              {/* <Questionnair_Component getType={this.state.stType} getLoanRequest={this.getLoanRequest}  
              countryList={countryList} viewLoadDoc={this.state.viewLoadDoc}/>    */}
                <Comp_Workbook_Form logInObj={userObject} WBFormName={this.state.stWBFormName} getType={this.state.stType}  />
              </div>
            </div>
            </div>
            

            </div>
           
  </div>
  <div id="quest_repo"></div>
     
        <Footer/>
      </div>)
    }
}
export default Int_WorkBook