import React from 'react';
export const Comp_Optionfor_Questionnaire=(props)=>{
    return(<div className="modal fade bd-example-modal-xl"  id="divOptionProForma" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl111" role="document">
        <div className="modal-content">
        <div className="modal-header bg-primary">
            <h5 className="modal-title text-white" id="exampleModalLabel">Create Questionnaire</h5>
            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" className="text-white">&times;</span>
            </button>
          </div>
          <form id="frmOptionProForma" name="frmOptionProForma">
         
          <div className="modal-body">
          <div className="row">
                    {/* <!-- left column --> */}
                    <div className="col-md-12">
                        <div className="card card-danger">
                                <input type="hidden" name="res_func" id="res_func" value="callBackAddLoan" />
                                <input type="hidden" name="curl_type" id="curl_type" value="addCategoryDocList11" />
                                <input type="hidden" name="m_type" id="m_type" value="addCategoryDocList11" />
                                <input type="hidden" name="type_id" id="type_id" value="2" />
                                <input type="hidden" name="local_token" id="local_token" value={props.logInObj[0].app_user_id}/>
                                <input type="hidden" name="app_user_id" id="app_user_id" value={props.logInObj[0].app_user_id} />
                                <input type="hidden" name="object_id" id="object_id" />
                                <input type="hidden" name="is_edit" id="is_edit"/>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                
                                                <div className="input-group input-group-sm">
                                                   <input type="radio" name="rdProformaQuest" id="rdProforma1" value="Blank Questionnaire"/>
                                                   <label for="rdProforma1">Blank Questionnaire</label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            
                                                <div className="input-group input-group-sm">
                                                
                                                   <input type="radio" name="rdProformaQuest" id="rdProforma2" value="Pro-Forma Questionnaire"/>
                                                   <label for="rdProforma2">Pro-Forma Questionnaire</label>

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                      
                                  
                                    <div className="col-md-6 respSSL"></div>
                                    <div id="testing2dd2222"></div>
                                </div>
                            
                        </div>
                    </div>
                </div>
          </div>
          <div className="modal-footer">
          <div id="getReponse" style={{color:"red"}}></div>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn _btnSetColor btn-primary">Create Now</button>
          </div>
          </form>
        </div>
        </div>
        </div>
    )
}
export default Comp_Optionfor_Questionnaire;