import $ from 'jquery';
import React from 'react';
import select2 from 'select2';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,BtnSetColor,CallMapMethod_CBack,AjaxCallMethod,
  GetImgCertUser_CBack,btnSetColor_CBack,RespBkMthdMapp} from '../function_lib/common_lib';
import {Comp_Import_Qust,Comp_Qust_Mapping,Comp_Remove_Quest_Bulk} from '../user_components/comp_import_qust';
import {Comp_List_Map_Quest} from '../user_components/comp_list_map_quest';
import Comp_Create_Question from '../user_components/comp_create_question';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let logInObj;
let docMasters;
let clsQuestMaster;
class Questionnaire_Master extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        clsQuestMaster=this;
        this.state={ listLoanDoc:[],stQuest:{},stListMappedQuest:[],stOnEvent:""};
 this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }


  setQuestMapData(data){
    this.setState({stQuest:data});
  }    

      result(vart){
          alert(vart);
      }

      UpdQuestionMapping(frmName){
        btnSetColor_CBack(this,frmName,"updateQuestionMapping",function(data,smg){
          alert(data);
        })
      }
    /* delete bulk Import */  
    DeleteBulkQust(frmName){
      GetImgCertUser_CBack(this,frmName,"deleteBulkQust",function(data,msg,userMsg){
        alert(data+msg);
      });
    }

      /* get upload file */
     UpdImportFile(frmName){
         GetImgCertUser_CBack(this,frmName,"uploadQuest",function(data,msg,userMsg){
          
          if(data==="001"){
            alert("Uploaded Successfully");
            window.location.reload();
          }
        });
      }
    /* edit quest */  
    setEditQuestMaster(item){
      //alert(item["type"]);
      $("#object_id").val(item["_id"]["$oid"]);
      $("#is_edit").val("true");
      $("#_loan_category").val(item["_category"]);
      $("#_year_id").val(item["year_id"]);
      $("#doc_header").val(item["doc_header"]);
      $("#sub_category").val(item["sub_category"]);
      $("#_title").val(item["title"]);
      $("#_type").val(item["type"]);
      $("#_descr").val(item["descr"]);
      $("#_priority").val(item["priority"]);
      $("#_job_business").val(item["job_business"]);

    }
    //end of edit quest
    /* delete quest */  
    deleteQuestMaster(title,objId){
      let respConf=window.confirm("Do you want to delete ["+title+"]");
      if(respConf===true){
        let map_quest=[];
        map_quest.push({name:"curl_type",value:"addCategoryDocList"});
        map_quest.push({name:"res_func",value:"callBackDelete"});
        map_quest.push({name:"obj_id",value:objId});
        map_quest.push({name:"is_delete",value:true});
        map_quest.push({name:"local_token",value:logInObj[0].id});
        map_quest["res_func"]="callBackDelete";
        map_quest["curl_type"]="addCategoryDocList";
        CallMapMethod_CBack(this,map_quest,function(data,msg){
          if(data=="001"){
            alert("Deleted Successfully");
            let rowObj= document.getElementById(objId);
            rowObj.remove();
          }
        })
      }
     
    }
    // end of delete quest Map

    /* get quest mapped data */
    getQuestMapData(onevent,objId){
      let map_doc=[];
      map_doc.push({name:"curl_type",value:"getCategoryDocList"});
      map_doc.push({name:"res_func",value:"callBackDocList"});
      map_doc.push({name:"local_token",value:logInObj[0].app_user_id});
      //map_doc.push({name:"loan_category",value:$("#loan_category_p").val()});
      map_doc.push({name:"type_id",value:"4"});
      map_doc.push({name:"on_event_of",value:onevent});
      map_doc.push({name:"object_id",value:objId});

      map_doc["res_func"]="callBackDocList";
      map_doc["curl_type"]="getCategoryDocList";

      CallMapMethod_CBack(this,map_doc,function(data,msg){
        //alert(data);
        if(data!=="0" && data!==""){
          let lsMapQuest=JSON.parse(data);

          clsQuestMaster.setState({stListMappedQuest:lsMapQuest,stOnEvent:onevent},function(){
            clsQuestMaster.forceUpdateHandler();
            RespBkMthdMapp(clsQuestMaster,"_setbackparam","onDelProCatRel");
          });
        }
      })
    }

      /* get Corrosponding Loan Doc List */
    getDocList(isSearch=false){
        let map_doc=[];
        map_doc.push({name:"curl_type",value:"getCategoryDocList"});
        map_doc.push({name:"res_func",value:"callBackDocList"});
        map_doc.push({name:"local_token",value:logInObj[0].app_user_id});
        //map_doc.push({name:"loan_category",value:$("#loan_category_p").val()});
        map_doc.push({name:"type_id",value:"4"});
        //--filters
        if(isSearch===true){
          map_doc.push({name:"serFilterYear",value:$("#serFilterYear").val()});
          map_doc.push({name:"serFilterHeader",value:$("#serFilterHeader").val()});
          map_doc.push({name:"serTxtSearch",value:$("#serTxtSearch").val()});
          map_doc.push({name:"serMapped",value:$("#serMapped").val()});
        }
        
        //--end of filters
        map_doc["res_func"]="callBackDocList";
        map_doc["curl_type"]="getCategoryDocList";
        CallMapMethod_CBack(this,map_doc,function(data,msg){
            //alert(data);
            if(data!=="0" && data!==""){
              let listCate=JSON.parse(data);
              var strData="";
              clsQuestMaster.setState({listLoanDoc:listCate},function(){
                $(".select2").select2();
                  $(".editable").click(function(event){
                      if($(this).children("input").length > 0)
                            return false;
                  
                      var tdObj = $(this);
                      let e_key=$(this).attr("e_key");
                      let e_id=$(this).attr("e_id");
                      
                      var preText = tdObj.html();
                      var inputObj = $("<input type='text' />");
                      tdObj.html("");
                  
                      inputObj.width(tdObj.width())
                              .height(tdObj.height())
                              .css({border:"0px",fontSize:"17px"})
                              .val(preText)
                              .appendTo(tdObj)
                              .trigger("focus")
                              .trigger("select");
                  
                      inputObj.keyup(function(event){
                        if(13 == event.which || 9===event.which) { // press ENTER-key
                          var text = $(this).val();
                          tdObj.html(text);
                          
                          clsQuestMaster.editLoadDocumentTb(e_id,e_key,text);
                          
                        }
                        else if(27 == event.which) {  // press ESC-key
                          tdObj.html(preText);
                        }
                      });
                  
                      inputObj.click(function(){
                        return false;
                      });
                    });
              });
              clsQuestMaster.forceUpdateHandler();
              $(".total_list").text((listCate.length));
          }
        });
    }

    getYearMaster(){
      let map=[];
      map.push({name:"curl_type",value:"getYearMaster"});
      map.push({name:"res_func",value:"callBackMain"});
      map.push({name:"local_token",value:logInObj[0].app_user_id});
      map["res_func"]="callBackMain";
      map["curl_type"]="getYearMaster";
      CallMapMethod_CBack(this,map,function(data,msg){
        if(data!=="" && data!=="0"){
          let yearList=JSON.parse(data);
          let strYear="<option value=\"0\">-Select Year-</option>";
          for(let i=0;i<yearList.length;i++){
            strYear+="<option value=\""+yearList[i]["id"]+"\">"+yearList[i]["display_year"]+"</option>";
          }
          //alert(strYear);
          $(".year_master").html(strYear);
        }
      });


    }

    getMainData(){
        var map=[];
    map.push({name:"curl_type",value:"getCUserWithDocuments"});
    map.push({name:"res_func",value:"callBackMain"});
    map.push({name:"local_token",value:logInObj[0].app_user_id});
    map.push({name:"parent_id",value:"0"});
    map.push({name:"multi_types",value:"4,5"});
    map.push({name:"get_documents",value:"0"});
    map["res_func"]="callBackMain";
    map["curl_type"]="getCUserWithDocuments";
    CallMapMethod_CBack(this,map,function(data,msg){
        if(data!=="0" && data!==""){
            
            let objData=JSON.parse(data);
            let cUserList=objData.c_user;
            docMasters=objData.doc_master;
           
            let strLoanMaster="<option>-Select Category-</option>";
            let strDocHeader="<option value=\"0\">-Header-</option>";
            let strDocMaster="<option>-Required-</option>";
           
            for(var l=0;l<cUserList.length;l++){
                if(cUserList[l]["type"]==="4"){
                   strLoanMaster+="<option value=\""+cUserList[l]["id"]+"\">"+cUserList[l]["col1"]+"</option>";
                }else if(cUserList[l]["type"]==="5"){
                    strDocHeader+="<option value=\""+cUserList[l]["col1"]+"\">"+cUserList[l]["col1"]+"</option>";
                }
            }
            $(".loan_category").html(strLoanMaster);
            $("#loan_category_p").html(strLoanMaster);
            $(".doc_header").html(strDocHeader);
            
            
            //alert("doc Length="+docMasters.lenght);
            //alert(docMasters[0].id);
            //alert(JSON.stringify(docMasters));
            //alert(docMasters[0]._id.$oid);
            for(var m=0;m<docMasters.length;m++){
                strDocMaster+="<option value=\""+docMasters[m]._id.$oid+"\">"+docMasters[m]["title"]+"</option>";
            }
            //alert(strDocMaster);
            //$("#doc_name").html(strDocMaster);
        }
    });
    }
    callBackAddLoan(data,msg){
        alert(data+msg);
        if(data==="not_validated"){
            $(".respSSL").html(msg);
        }else if(data==="001"){
            $("#frmCreate").find("input[type=text]").val("");
            $(".respSSL").html(msg);
            this.getDocList();
        }else {
            $(".respSSL").html(msg);
        }
    }

      componentDidMount(){
        this.getDocList();
        this.getMainData();
        this.getYearMaster();
         AjaxCallMethod(this,"okSuccCNOT");

         $('#divEmployee').on('hidden.bs.modal', function () {
          window.location.reload();
          // do something…
        }); 
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          document.body.appendChild(script);
      }
      onRemoveChildMapp(param1,param2){
        //alert(param1);
        let confirmDel=window.confirm("Confirm !!Do you want to remove child mapping");
        if(confirmDel===true){
          let map_del=[];
          map_del.push({name:"curl_type",value:"removeQuestMappingChild"});
          map_del.push({name:"res_func",value:"resCBack"});
          map_del.push({name:"local_token",value:logInObj[0].app_user_id});
          map_del.push({name:"quest_id",value:param1});
          map_del["curl_type"]="removeQuestMappingChild";
          map_del["res_func"]="resCBack";
          CallMapMethod_CBack(clsQuestMaster,map_del,function(data,msg){
            if(data==="001"){
              clsQuestMaster.getQuestMapData("parent",param2);  
            }
            
          });
        }
       

      }  
    
      
     

    render(){
        
      
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-12 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
                <h5 class="card-title">Questionnaire Master</h5>
            </div>
            <div class="right_tb">
            <span class="float-right mt-2 ml-2 badge bg-primary pointer" data-toggle="modal" data-target="#divEmployee">Add New</span>
            <span class="float-left mt-2  ml-2 badge bg-primary pointer" data-toggle="modal" data-target="#divImportQuest">Bulk Import</span>
            <span class="float-left mt-2 ml-2 badge bg-primary pointer" data-toggle="modal" data-target="#divBulkRemove">Delete Bulk</span>
            
     
            </div>
        </div>
                </div>

                <div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
            <span class="float-left mt-2 ml-2 badge ">
                <select id="serFilterYear" name="serFilterYear" className="form-control year_master">
                </select>
            </span>
            <span class="float-left mt-2 ml-2 badge ">
                <select id="serFilterHeader" name="serFilterHeader" className="form-control doc_header">
                </select>
            </span>
            <span class="float-left mt-2 ml-2 badge ">
                <select id="serMapped" name="serMapped" className="form-control">
                  <option value={"0"}>Is Mapped</option>
                  <option value={"parent"}>Parent</option>
                  <option value={"child"}>Child</option>
                </select>
            </span>
            <span class="float-left mt-2 ml-2 badge ">    
                <input id="serTxtSearch" name="serTxtSearch" type="text" placeholder="search by question title" />
            </span>
            
            <span class="float-left mt-3 btn badge bg-primary pointer" onClick={()=>this.getDocList(true)}>Search</span>
            </div>
            <div class="right_tb">
          
            </div>
        </div>
                </div>
                </div>

              <div class="card-body">

              <table class="dtgrid table table-bordered table-hover text-sm text-nowrap12 table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                        <th>Document</th>
                        <th>Title</th>
                        <th>Desc</th>
                        <th>Type</th>
                        <th>Priority</th>
                        <th>is Mapped</th>
                       <th>Status</th> 
                       <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {(this.state.listLoanDoc.map((item,index)=>{
                      let mappPassObject=item["_id"]["$oid"];
                      
                      if(item["on_event"]==="child"){
                        mappPassObject=item["p_quest"];
                      }
                        return(<tr id={item["_id"]["$oid"]}>
                            <td>{index+1}</td>
                            <td>{item["doc_header"]}</td>
                            <td>{item["title"]}</td>
                            <td>{item["descr"]}</td>
                            <td>{item["type"]}</td>
                            <td className='editable' e_id={item["_id"]["$oid"]} e_key={"priority"}>{item["priority"]}</td>
                            <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divListMapQuest" onClick={()=>this.getQuestMapData(item["on_event"],mappPassObject)}>{item["on_event"]}</a></td>
                            <td>{item["status"]}</td>
                            <td>
                            <div className="main-menu manu-color-white">
                        <ul>
                          <li class="dropdown">
                        <a className="font-sm" href="javascript:void(0)"><span className="badge bg-primary">Action <i class="ion-ios-arrow-down"></i></span></a>
                        <ul class="sub-menu">
                        <li><a class="dropdown-item" data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)" onClick={()=>this.setEditQuestMaster(item)}><i class="fa fa-pencil text-inverse m-r-10">Edit</i></a></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#divQustMapping" onClick={()=>this.setQuestMapData(item)}>Map Quest.</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" onClick={()=>this.deleteQuestMaster(item["title"],item["_id"]["$oid"])}><i class="fa fa-window-close text-danger">Delete</i></a></li>
                          </ul>
                          </li>
                          </ul>
                          </div>
                            </td>
                           
                        </tr>)
                    }))}
                  </tbody>
                </table>
             
              
              </div>
            </div>
            </div>
           
             
            </div>
  </div>
     
        <Footer/>
        <Comp_Import_Qust logInObj={logInObj} UpdImportFile={this.UpdImportFile}/>
        <Comp_Remove_Quest_Bulk logInObj={logInObj} DeleteBulkQust={this.DeleteBulkQust}/>
        <Comp_Qust_Mapping logInObj={logInObj} listQuest={this.state.listLoanDoc} quest={this.state.stQuest} UpdQustMapping={this.UpdQuestionMapping} />
        <Comp_List_Map_Quest listMappedQuest={this.state.stListMappedQuest} titOnEvent={this.state.stOnEvent} />
        <Comp_Create_Question logInObj={logInObj}/>

      </div>)
    }
}
export default Questionnaire_Master