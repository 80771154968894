import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {new_enc_dec_m,CallMapMethod,BtnSetColor,webUrlPath,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp,setDocLinks,GetImgCertUser} from '../function_lib/common_lib';
import {getCountryList} from '../function_lib/city_india';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';

import Questionnair_Component from '../user_components/questionnair_component';
import Questionnaire_Comp_Func from '../user_components/questionnaire_comp_func';


let loginUser="";
let userObject="";
let clsQuestionnair;

let webUrl;
let rf="0";
let queries;

/* internal param */
let yearRf="0";
let yearTitle="";
let countryList=[];

class Questionnair extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!==null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        countryList=getCountryList();
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(typeof queries.doc_rf!=="undefined"){
                    rf=queries.doc_rf;
                  }
                  if(typeof queries.rf!=="undefined"){
                    yearRf=queries.rf;
                  }
                  if(typeof queries.dis!=="undefined"){
                    yearTitle=new_enc_dec_m("decrypt",queries.dis);
                  }
          }
          this.state={ listLoanRequest:[],viewLoadDoc:[],stType:"questionnair",
                      stYearRf:yearRf,stYearTitle:yearTitle};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          webUrl=webUrlPath();
          clsQuestionnair=this;
      }

      
  getTaxRequest(status="0"){
    //child component use this method loan_document_upload_comp
    var map=[];
  map.push({name:"curl_type",value:"getTaxDocOpen"});
  map.push({name:"res_func",value:"callBackloanR"});
  map.push({name:"local_token",value:userObject[0].id});
  if(rf!=="0"){
    map.push({name:"loan_request_id",value:rf});
  }
  
  map.push({name:"parent_id",value:"0"});
  map.push({name:"type_id",value:"4"});
  map["res_func"]="callBackloanR";
  map["curl_type"]="getTaxDocOpen";
    CallMapMethod_CBack(clsQuestionnair,map,function(data,msg){
      //alert("i=="+data);
      //$("#quest_repo").html(data);
      if(data!=="0" && data!==""){
        let listData=JSON.parse(data);
        var strData="";
        clsQuestionnair.setState({listLoanRequest:listData},()=>{
            clsQuestionnair.viewLoanDoc(listData[0]);
            
        });
        //clsQuestionnair.forceUpdateHandler();
      }
    });
  }
  
  viewLoanDoc(itemRow){
   
    // alert(JSON.stringify(itemRow));
  let strItemRow="["+JSON.stringify(itemRow)+"]";
  let jsItemRow=JSON.parse(strItemRow);
  this.setState({viewLoadDoc:jsItemRow},()=>{
    //clsQuestionnair.showMe("#exampleModal");
    //alert("main call=="+JSON.stringify(jsItemRow));
    
  });
  //this.forceUpdateHandler();
  }

      
  okSucc(data,smg){
    if(data==="001"){
      alert("record added successfully");
      clsQuestionnair.getTaxRequest();
      $("#frmCreate").find("input[type=text]").val("");
    }
    // alert(data);
  }
  onDocSubmit(frmName){
    GetImgCertUser(clsQuestionnair,frmName,"updateTaxRequestData","okSuccUpdate");
}
okSuccUpdate(data,msg,msg2){
  //alert(msg2);
  if(data!=="0" && data!==""){
    $(".respSSL_lod").text("Updates Successfully");
    this.props.getLoanRequest();
  }
   
}
      
      componentDidMount(){
         this.getTaxRequest();
         if(queries.doc_rf!=null){
          setDocLinks(queries.doc_rf);
         }
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
        
          document.body.appendChild(script);
      }
    

    render(){
   
        return(<div className="bg-gray">
            <Header doc_rf={rf}/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-8 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title">Questionnair ({this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["year_display"]:""})</h5>
                {rf!=="0" && rf!==0?<p>{this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["first_name"]:''}</p>:''}
                </div>
                <input type="hidden" id="maxRows" name="state" value={10}/>
              <div class="card-body">
              {/* <Questionnair_Component getType={this.state.stType} getLoanRequest={this.getLoanRequest}  
              countryList={countryList} viewLoadDoc={this.state.viewLoadDoc}/>    */}
                <Questionnaire_Comp_Func logInObj={userObject} getType={this.state.stType} getLoanRequest={this.getLoanRequest}  
              countryList={countryList} viewLoadDoc={this.state.viewLoadDoc} />
              </div>
            </div>
            </div>
            {/* side card */}
              <div style={{position:"fixed",right:"0px",top:"130px"}} className="col-md-4 pb-20px pt-20px">
              <div class="card" style={{width: "100%;"}}>
  
  <div class="card-body">
     <h5 class="card-title">Tax Questionnaire</h5>
    <p class="card-text">Year wise status/records</p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>General Info</li>
    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>Wage income</li>
    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>Interest income</li>
    <li class="list-group-item d-flex">
      <button type="button" onClick={()=>this.onDocSubmit("#msform")} style={{marginRight:"auto"}} className="button-37 mb-2 mr-2 btn btn-primary bg-primary text-white">Save</button>
      <button type="button" onClick={()=>this.onDocSubmit("#msform")} style={{marginRight:"auto"}} className="button-37 mb-2 btn btn-primary bg-primary text-white">Submit</button>
      </li>
  </ul>
  
</div>

              </div>

             

            </div>
           
  </div>
  <div id="quest_repo"></div>
     
        <Footer/>
      </div>)
    }
}
export default Questionnair