import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { useLocation } from 'react-router-dom';

// import routes from "./routes";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Products from './pages/product_list';
import Product_Landing from './pages/product_landing';
import Step_Two from './pages/step_two';
import Login from './pages/login';
import ContactUs from './pages/contact_us';
//import Blogs from './pages/Blogs_Articles';
import Receipt from './pages/receipt';

//Sample of Component
import AllComp from './sample_comp/all_component';
import Blogs_Page from './pages/blogs_page';
import Blog_Landing from './pages/blog_landing';
import Comp_Case_Landing from './sample_comp/comp_case_landing';
import Jobs_Page from './pages/jobs_page';
import Job_Landing from './pages/job_landing';

//Login User Pages
import DashboardNew from './user_pages/dashboard_new';
import Questionnair from './user_pages/questionnair';
import Upload_Document_List from './user_pages/upload_documents_list';

import Tax_Payer_Mgmt from './user_pages/tax_payer_mgmt';
import Tax_Payer_Master from './user_pages/tax_payer_master';
import Cpa_Mgmt from './user_pages/cpa_mgmt';

import Category_Master from './user_pages/category_master';
import Header_Master from './user_pages/header_master';
import Document_List_Master from './user_pages/document_list_master';
import Questionnaire_Master from './user_pages/questionnaire_master';
import Int_WorkBook from './user_pages/int_workbook';
import Fy_Year_Master from './user_pages/fy_year_master';

import Tax_Request_Opn from './user_pages/tax_request_opn';

import My_Profile from './user_pages/my_profile';
import Invoice_Receipt from './user_pages/invoice_receipt';
import My_Orders from './user_pages/my_orders';
import Apply_Jobs from './pages/apply_jobs';



class App extends React.Component{
  constructor(props){
    super(props);
    
  }
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
     
  render(){
    
    return <Router><Switch>
      <Route exact path="/contact_us" component={ContactUs}/>
      {/* <Route exact path="/contactus" render={props => <ContactUs {...props} />}/> */}
      <Route exact path="/blogs" component={Blogs_Page} />
      <Route exact path="/blog/:name" component={Blog_Landing} />
      <Route exact path="/case_studies/:name" component={Blog_Landing}/>
      



      <Route exact path="/jobs" component={Jobs_Page} />
      <Route exact path="/job_landing" component={Job_Landing}/>

      <Route exact path="/apply_jobs" component={Apply_Jobs} />

      <Route exact path="/products" component={Products} />
      <Route exact path="/products/:name" component={Products}/>
      <Route exact path="/shop_all" component={Products} />
      <Route exact path="/shop_all/:name" component={Products}/>
      
      <Route exact path="/product/:name" component={Product_Landing}/>
      <Route exact path="/Login" component={Login} />
      <Route exact path="/steptwo" component={Step_Two}/>
      {/* <Route exact path="/invoice_receipt" component={Receipt} /> */}
      <Route exact path="/dashboard" component={DashboardNew}/>

      <Route exact path="/quest_overview" component={Questionnair}/>
      <Route exact path="/workbook" component={Int_WorkBook}/>
      <Route exact path="/document_list" component={Upload_Document_List}/>
      <Route exact path="/tax_payer_mgmt" component={Tax_Payer_Mgmt}/>
      <Route exact path="/tax_payer_master" component={Tax_Payer_Master}/>
      <Route exact path="/cpa_mgmt" component={Cpa_Mgmt}/>
      {/* only for developer */}
      <Route exact path="/fy_year_master" component={Fy_Year_Master} />
      <Route exact path="/category_master" component={Category_Master} />
      <Route exact path="/header_master" component={Header_Master}/>
      <Route exact path="/document_list_master" component={Document_List_Master}/>
      <Route exact path="/questionnaire_master" component={Questionnaire_Master}/>
      <Route exact path="/tax_request_opn/:name" component={Tax_Request_Opn} />
      {/* end of developer */}
      <Route exact path="/invoice_receipt" component={Invoice_Receipt} />
      <Route exact path="/my_profile" component={My_Profile}/>
      <Route exact path="/my_orders" component={My_Orders} />
      <Route exact path="/my_invoices" component={My_Orders} />
    <Route exact path="*/*" component={Pages} />
  </Switch></Router>
  }
}
export default App;