import React from 'react';
export const Comp_Create_Question=(props)=>{
    return(<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
        <div className="modal-header bg-primary">
            <h5 className="modal-title text-white" id="exampleModalLabel">Create New Question</h5>
            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" className="text-white">&times;</span>
            </button>
          </div>
          <form id="frmCreateLoan" name="frmCreateLoan">
         
          <div className="modal-body">
          <div className="row">
                    {/* <!-- left column --> */}
                    <div className="col-md-12">
                        <div className="card card-danger">
                                <input type="hidden" name="res_func" id="res_func" value="callBackAddLoan" />
                                <input type="hidden" name="curl_type" id="curl_type" value="addCategoryDocList" />
                                <input type="hidden" name="m_type" id="m_type" value="addCategoryDocList" />
                                <input type="hidden" name="type_id" id="type_id" value="2" />
                                <input type="hidden" name="local_token" id="local_token" value={props.logInObj[0].app_user_id}/>
                                <input type="hidden" name="app_user_id" id="app_user_id" value={props.logInObj[0].app_user_id} />
                                <input type="hidden" name="object_id" id="object_id" />
                                <input type="hidden" name="is_edit" id="is_edit"/>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">Category *</label>
                                                <div className="input-group input-group-sm">
                                                    <select id="_loan_category" name="_loan_category" className="form-control loan_category required">
                                                      <option value="0">-Select-</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">Year *</label>
                                                <div className="input-group input-group-sm">
                                                    <select id="_year_id" name="_year_id" className="form-control year_master required">
                                                      <option value="0">-Select-</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                       <div className='row'> 
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">Doc Header *</label>
                                                <div className="input-group input-group-sm">
                                                    <select id="doc_header" name="doc_header" className="form-control required doc_header">
                                                      <option value="0">-Select-</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                                <label for="exampleInputEmail1">Group Title *</label>
                                                <div className="input-group input-group-sm">
                                                <input type="text" id="sub_category" name="sub_category" className="form-control" placeholder="Title" /> 
                                                </div>
                                            </div>
                                        </div>
    
                                        {/* <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">Doc Name *</label>
                                                <div className="input-group input-group-sm">
                                                    <select id="doc_name" name="doc_name" className="form-control doc_name">
                                                      <option value="0">-Select-</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
    
                                       
                                       
                                        <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                                <label for="exampleInputEmail1">Question *</label>
                                                <div className="input-group input-group-sm">
                                                <input type="text" id="_title" name="_title" className="form-control" placeholder="Question" /> 
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                                <label for="exampleInputEmail1">Type</label>
                                                <div className="input-group input-group-sm">
                                                <select id="_type" name="_type" className="form-control required">
                                                      <option value="0">-Select-</option>
                                                      <option value="text">text</option>
                                                      <option value="textarea">textarea</option>
                                                      <option value="file_upload">file_upload</option>
                                                      <option value="text,file_upload">text,file_upload</option>
                                                      <option value="radio_button">radio_button</option>
                                                      <option value="dropdown">dropdown</option>
                                                      <option value="date">date</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                                <label for="exampleInputEmail1">Description/Default Values(>seperated)</label>
                                                <div className="input-group input-group-sm">
                                                  <textarea id="_descr" name="_descr" className="form-control"></textarea>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                            <label for="exampleInputEmail1">Priority Index</label>
                                                <div className="input-group input-group-sm">
                                                <input type="number" id="_priority" name="_priority" className="form-control" placeholder="Priority" />
                                                </div>
                                            </div>
    
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                                <label for="exampleInputEmail1">Required Case</label>
                                                <div className="input-group input-group-sm">
                                                <select id="_job_business" name="_job_business" className="form-control required">
                                                      <option value="0">-Select-</option>
                                                      <option value="job">Job</option>
                                                      <option value="business">Business</option>
                                                      <option value="all">All</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                            
                                                <div className="input-group input-group-sm">
                                                <input className='checkbox' type="checkbox" id="_is_mandatory" name="_is_mandatory" />
                                                <label for="_is_mandatory">Is Mandatory</label>
                                                </div>
                                            </div>
    
                                        </div> */}
                                    </div>
                                  
                                    <div className="col-md-6 respSSL"></div>
                                    <div id="testing2dd2222"></div>
                                </div>
                            
                        </div>
                    </div>
                </div>
          </div>
          <div className="modal-footer">
          <div id="getReponse" style={{color:"red"}}></div>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
          </div>
          </form>
        </div>
        </div>
        </div>
    )
}
export default Comp_Create_Question;